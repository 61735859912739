/* globals document */

import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
import * as React from 'react';

import { linkResolver } from './prismic-link-resolver';

export const onRouteUpdate = ({ prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector('#reach-skip-nav');
    if (skipLink) {
      skipLink.focus();
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver
      }
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
