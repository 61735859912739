exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'blog':
      return `/blog/${doc.uid}`;
    case 'article':
      return `/article/${doc.uid}`;
    case 'job_posting':
      return `/careers/${doc.uid}`;
    case 'press_release':
      return `/press/${doc.uid}`;
    case 'info_page':
    case 'landing_page':
      return `/${doc.uid}`;
    case 'sales_site_designers':
      return `/partners/designers`;
    case 'sales_site_developers':
      return `/partners/developers`;
    case 'sales_site_integrations':
      return `/partners/integrations`;
    case 'sales_site_data_migration':
      return `/partners/data-migration`;
    default:
      return '/';
  }
};
